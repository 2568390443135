<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-row no-gutters>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFranchise"
          :items="franchises"
          :item-text="getFullFranchiseName"
          item-value="Name"
          label="Select Franchise"
          persistent-hint
          return-object
          solo
          v-on:change="filterACL"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          class="ml-3"
          v-model="selectedUserGroup"
          :items="userGroups"
          item-text="Name"
          item-value="Name"
          label="Select User Group"
          persistent-hint
          return-object
          solo
          v-on:change="filterACL"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              large
              class="ml-2  mt-1"
              @click="clearSearch()"
              color="info"
              v-bind="attrs"
              v-on="on"
            >
              mdi-close-circle
            </v-icon>
          </template>
          <span>Clear</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <progress-spinner :isLoading="loading" />

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-data-table
            v-model="selectedItems"
            :headers="headers"
            :items="rosterData"
            sort-by="FirstName"
            item-key="Id"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Employees with ACL Entries</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                  :retain-focus="false"
                  v-model="dialog"
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ assignFormTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 200px">
                      <v-radio-group v-model="userGroupChange" column>
                        <v-radio
                          v-for="group in allowedUserGroups"
                          :key="group.Id"
                          :label="group.Name"
                          :value="group.Id"
                        ></v-radio>
                      </v-radio-group>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        Cancel
                      </v-btn>

                      <v-btn
                        v-if="singleAssign"
                        color="blue darken-1"
                        text
                        @click="assignUserGroup"
                      >
                        OK
                      </v-btn>
                      <v-btn
                        v-else
                        color="blue darken-1"
                        text
                        @click="bulkAssignUserGroup"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  :retain-focus="false"
                  v-model="dialogDelete"
                  max-width="300px"
                >
                  <v-card>
                    <v-card-title class="text-h5">{{
                      deleteFormTitle
                    }}</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-if="singleDelete"
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-btn
                        v-else
                        color="blue darken-1"
                        text
                        @click="bulkRemoveUserGroup"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.Name="{ item }">
              {{ item.Employee.FirstName }}
              {{ item.Employee.LastName }}</template>
            <template v-slot:item.UserGroupId="{ item }">
              <v-btn
                min-width="130"
                class="primary pa-0 text-capitalize font-weight-bold"
                @click="handlePromptChangeUserGroup(item)"
              >
                {{ item?.Employee?.UserGroups[0]?.Name ?? '' }}
              </v-btn>
            </template>
            <template v-slot:item.IsPrimaryFranchise="{ item }">
              <span class="text-uppercase">{{item.IsPrimaryFranchise}}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="py-3" />
    <v-dialog v-model="showGroupAssignModal" activator="parent" width="500">
      <v-card>
        <v-card-title>Define group</v-card-title>
        <v-card-text class="black--text">What user group should
          <b>{{`${this.selectedEmployee?.Employee.FirstName} ${this.selectedEmployee?.Employee.LastName}`}}</b>
          have in the <b>{{this.selectedEmployee?.Franchise?.Name ?? ''}}</b> area?</v-card-text>
        <v-card-text class="black--text">
          <v-radio-group v-model="selectedUserGroup">
            <v-radio v-for="(option, index) in this.userGroups" :label="option.Name" :value="option.Id" :key="index"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text class="blue--text" @click="showGroupAssignModal = false">
            Cancel
          </v-btn>
          <v-btn text class="blue--text" @click="assignUserGroup()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require("axios");
export default {
  name: "Roster",
  data() {
    return {
      search: "",
      singleSelect: false,
      rosterData: [],
      employees: [],
      singleAssign: true,
      singleDelete: true,
      selectedItems: [],
      userGroups: [],
      currentUserGroupOrder: localStorage.getItem("groupOrder"),
      franchises: [],
      allowedUserGroups: [],
      selectedUserGroup: null,
      selectedFranchise: null,
      page: 1,
      pageSize: 10,
      userGroupChange: null,
      deleteId: [],
      loading: true,
      emp: {
        employeeIds: [],
      },
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        {
          text: "Permission Location",
          value: "Franchise.Name"
        },
        {
          text: "User Group Assignment",
          value: "UserGroupId",
          sortable: false
        },
        {
          text: "Primary?",
          value: "IsPrimaryFranchise"
        }
      ],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialog: false,
      dialogDelete: false,
      selectedEmployee: null,
      showGroupAssignModal: false
    };
  },

  methods: {
    async handlePromptChangeUserGroup(item) {
      if (this.userGroups.length === 0) {
        await this.getUserGroups();
      }
      this.selectedEmployee = item;
      this.showGroupAssignModal = true;
    },
    async getEmployees() {
      this.loading = true;
      this.employees = [];
      this.userGroups = [];
      try {
        let { data } = await axios.get(`employees/acl`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.employees = data;
        this.rosterData = data;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    filterACL() {
      let filtered = null;
      if (this.selectedUserGroup && this.selectedFranchise) {
        filtered = this.employees.filter(
          (item) =>
            item.Employee?.UserGroups[0]?.Id == this.selectedUserGroup.Id &&
            item.Franchise?.Id == this.selectedFranchise.Id
        );
      } else if (this.selectedFranchise) {
        filtered = this.employees.filter(
          (item) => item.Franchise?.Id == this.selectedFranchise.Id
        );
      } else {
        filtered = this.employees.filter(
          (item) => item.Employee?.UserGroups[0]?.Id == this.selectedUserGroup.Id
        );
      }
      this.rosterData = filtered;
    },

     clearSearch() {
      this.selectedUserGroup = null;
      this.selectedFranchise = null;
      this.rosterData = this.employees;
    },

    async getUserGroups() {
      this.loading = true;
      this.userGroups = [];
      try {
        let { data } = await axios.get(`user-groups`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.userGroups = data.sort((a,b) => a.Order - b.Order);
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async getAllowedUserGroups() {
      this.loading = true;
      this.userGroups = [];
      try {
        let { data } = await axios.get(
          `auth/current-user/allowed-user-groups`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.allowedUserGroups = data;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async getFranchises() {
      this.loading = true;
      this.userGroups = [];
      try {
        let { data } = await axios.get(`auth/current-user/authorized-franchises`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.franchises = data;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    getFullFranchiseName(item) {
      return `${item.Name} ${item.Code}`;
    },

    handlePageChange(value) {
      this.page = value;
      this.getEmployees();
    },

    editItem(item) {
      this.singleAssign = true;
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.singleDelete = true;
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async bulkAssignPriority() {
      this.loading = true;
      this.emp.employeeIds = [];
      for (const obj of this.selectedItems) {
        this.emp["employeeIds"].push(obj.Id);
        this.emp["userGroupId"] = this.userGroupChange;
      }
      try {
        await axios.put(`employees/bulk-priority-status-update`, this.emp, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.$toast.success("Priority assigned successfully");
        this.close();
        this.emp.employeeIds = [];
        this.getEmployees();
        this.getUserGroups();
        this.getWithoutPriorityStatus();
        this.singleAssign = false;
        this.selectedItems = [];
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    toggleSingleAssign() {
      this.singleAssign = false;
      this.dialog = true;
    },

    toggleSingleDelete() {
      this.singleDelete = false;
      this.dialogDelete = true;
    },

    async bulkRemoveUserGroup() {
      this.loading = true;
      this.emp.employeeIds = [];
      for (const obj of this.selectedItems) {
        this.emp.employeeIds.push(obj.Employee.Id);
      }
      this.emp.employeeIds = [...new Set(this.emp.employeeIds)];

      try {
        await axios.put(`user-groups/remove-from-employees`, this.emp, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.$toast.success("User group removed successfully");
        this.close();
        this.emp.employeeIds = [];
        this.getEmployees();
        this.getUserGroups();
        this.singleDelete = false;
        this.selectedItems = [];
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
      this.closeDelete();
    },

    async bulkAssignUserGroup() {
      this.loading = true;
      this.emp.employeeIds = [];
      for (const obj of this.selectedItems) {
        this.emp.employeeIds.push(obj.Employee.Id);
      }
      this.emp.employeeIds = [...new Set(this.emp.employeeIds)];
      try {
        await axios.post(
          `/user-groups/${this.userGroupChange}/assign-to-employees`,
          this.emp,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.$toast.success("User group changed sucessfully");
        this.close();
        this.emp.employeeIds = [];
        this.getEmployees();
        this.getUserGroups();
        this.singleDelete = false;
        this.selectedItems = [];
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
      this.closeDelete();
    },

    async assignUserGroup() {
      this.loading = true;
      try {
        await axios.put(
          `employees/${this.selectedEmployee.EmployeeId}/acl`,
          {
            franchiseId: this.selectedEmployee.FranchiseId,
            userGroupId: this.selectedUserGroup,
            roleId: this.selectedEmployee.RoleId,
            email: this.selectedEmployee.Employee.WorkEmail
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.$toast.success("User group changed successfully");
        this.close();
        this.selectedUserGroup = null;
        this.selectedEmployee = null;
        this.showGroupAssignModal = false;
        this.getEmployees();
        this.getUserGroups();
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
        this.closeDelete();
      }
    },

    async deleteItemConfirm() {
      this.loading = true;
      try {
        this.emp.employeeIds.push(this.editedItem.Employee.Id);
        await axios.put(`user-groups/remove-from-employees`, this.emp, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.$toast.success("User group removed successfully");
        this.close();
        this.emp.employeeIds = [];
        this.getEmployees();
        this.getUserGroups();
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
        this.closeDelete();
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async assignPriorityStatusToEmployee() {
      this.loading = true;
      try {
        await axios.put(
          `/employees/${this.editedItem.Id}/priority-statuses/${this.priorityChange}`,
          "",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.$toast.success("Priority assigned successfully");
        this.close();
        this.getEmployees();
        this.getUserGroups();
        this.getWithoutPriorityStatus();
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getEmployees();
    this.getUserGroups();
    this.getFranchises();
    this.getAllowedUserGroups();
  },
  computed: {
    assignFormTitle() {
      return this.singleAssign
        ? "Which user group you want to assign to this employee?"
        : "Which user group you want to assign to these employees?";
    },
    deleteFormTitle() {
      return this.singleDelete
        ? "Are you sure you want to remove user group for this employee?"
        : "Are you sure you want to remove user group for these employees?";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>
